.hamburger-menu-wrap {
  position: absolute;
  top: 0;
  right: 0;
}

// Hamburger Menu
.hamburger-menu {
  position: relative;
  width: auto;
  height: 50px;
  cursor: pointer;

  .toggler {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 50px;
    opacity: 0;
    padding: 0;
  }

  .hamburger {
    width: 28px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
  }

  /* Hamburger Line */
  .hamburger > span {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: $black;
    display: flex;
    align-items: center;
    justify-content: left;
    transition: all 0.4s ease;
  }

  /* Hamburger Lines - Top & Bottom */
  .hamburger > span::before,
  .hamburger > span::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -7px;
    width: 100%;
    height: 2px;
    background: inherit;
  }

  /* Moves Line Down */
  .hamburger > span::after {
    top: 7px;
    width: 100%;
  }

  /* Toggler Animation */
  &.close .hamburger > span {
    transform: rotate(135deg);
  }

  /* Turns Lines Into X */
  &.close .hamburger > span:before,
  &.close .hamburger > span:after {
    top: 0;
    transform: rotate(90deg);
    width: 100%;
  }

  &.close .hamburger > span:after {
    display: none;
  }

  /* Rotate On Hover When Checked */
  &.close:hover .hamburger > span {
    transform: rotate(225deg);
  }

  /* Show Menu */
  &.close ~ .menu {
    visibility: visible;
  }

  &.close ~ .menu > span {
    transform: scale(1);
    transition-duration: 0.75s;
  }

  &.close ~ .menu > span > span {
    opacity: 1;
    transition: opacity 0.4s ease 0.4s;
  }
}

// overrides
.header {
  .hamburger-menu-wrap {
    right: 0.5rem;
    top: 10px;
  }
}
