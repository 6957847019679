.header {
    &__image {
        position: relative;
        z-index: 0;

        img {
            display: block;
            max-width: 100%;
            height: 100%;
            margin: auto;
        }

        p {
            margin-bottom: 0;
        }

        .mod-custom {
            padding: 0 !important;
        }
    }

    &__logo-main {
        position: relative;
        z-index: 1;

        a {
            display: block;
            transition: all 400ms ease-out;

            &:hover {
                transform: scale(1.02);
            }
        }

        h1 {
            font-size: 2.5rem;
            color: $primary;
            margin-bottom: -15px;

            @include media-breakpoint-up(lg) {
                font-size: 5rem;
            }
        }

        h2 {
            font-size: 1.5rem;
            margin-left: 6rem;
            color: $black;

            @include media-breakpoint-up(lg) {
                font-size: 2.5rem;
            }
        }
    }

    .nav-fill > .nav-link, .nav-fill .nav-item {
        flex: 1 1 0;
    }

    &__subheader {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5rem;

        img {
            margin: 0;
            padding: 0;
            max-width: 1200px;
        }

        &-text {
            background-color: rgba($gray-600, 0.9);
            color: $white;
            position: static;
            right: -4rem;
            bottom: -5rem;
            padding: 4rem;

            @include media-breakpoint-up(lg) {
                position: absolute;
            }


            @include media-breakpoint-up(xxl) {
                right: 0;
            }


            h3 {
                color: $white;
            }

            p {
                margin-bottom: 1rem;
            }
        }
    }

    .mobile-topbar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        background-color: rgba($white, 1);
        box-shadow: 0 0.125rem 0.25rem rgba(51, 51, 51, 0.075);

        .logo-image {
            width: 55px;
            position: relative;
            top: -8px;
        }
    }
}

.home {
    .header__image {
        margin-bottom: -5rem;
    }
}

.main-menu {
    max-width: 748px;
    transition: all 300ms ease;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        max-width: 1024px;
    }


    @include media-breakpoint-up(xl) {
        max-width: 1430px;
    }


    .nav-item.default {
        display: none;
    }

    .nav-link {
        text-transform: uppercase;
        font-size: 1rem;

        @include media-breakpoint-up(lg) {
            font-size: 1.25rem;
        }


        @include media-breakpoint-up(xxl) {
            font-size: 1.5rem;
        }
    }

    &.subsite-nav {
        max-width: 748px;

        @include media-breakpoint-up(lg) {
            max-width: 1024px;
        }


        @include media-breakpoint-up(xl) {
            max-width: 1450px;
        }


        .nav-item {
            display: flex;
            align-items: center;

            &.default {
                display: flex;
            }
        }

        .logo-image {
            transition: all 300ms ease;
            width: 74px;
        }

        .nav-link {
            @include media-breakpoint-up(lg) {
                font-size: 1rem;
            }


            @include media-breakpoint-up(xxl) {
                font-size: 1.25rem;
            }
        }
    }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .sticky {
        background-color: rgba($white, 0.7) !important;
        backdrop-filter: blur(4px);
    }

    .menu-overlay,
    .header__subheader-text {
        backdrop-filter: blur(4px);
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 20;
    background-color: rgba($white, 1);
    box-shadow: 0 0.125rem 0.25rem rgba(51, 51, 51, 0.075);

    &.shrink__home {
        .main-menu {
            .nav-item.default {
                display: block;
            }

            .nav-item {
                display: flex;
                align-items: center;
            }

            .nav-link {
                font-size: 1.25rem;
            }
        }

        .nav-fill > .nav-link, .nav-fill .nav-item {
            flex: 1 1 0;
        }
    }

    &.shrink {
        .logo-image {
            width: 50px;
        }

        .main-menu {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }
    }

    .main-menu {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    + .main {
        padding-top: 86px;
    }
}

.menu-overlay {
    background: rgba($primary, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    opacity: 0;
    transition: opacity 300ms ease;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        width: 200vw;
        height: 200vw;
        color: $black;
        flex: none;
        backface-visibility: hidden;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            > ul {
                list-style: none;
                padding: 0 1em;
                margin: 0;
                display: block;
                width: 400px;
                max-height: 100vh;

                > li {
                    font-size: rem-calc(23);
                    display: block;

                    a {
                        text-transform: uppercase;
                        color: $white;
                        cursor: pointer;

                        &:hover {
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    &.open {
        pointer-events: auto;
        visibility: visible;
        opacity: 1;

        > div {
            > div {
                opacity: 1;
            }
        }
    }

    .nav-item.default {
        display: none;
    }
}

.close {
    color: $white;
    float: left;
    font-size: 2em;
    position: absolute;
    top: 20px;
    right: 15px;
    opacity: 1;
}

.close:hover,
.close:focus {
    color: $black;
    text-decoration: none;
    cursor: pointer;
}
