.bg-gray {
    background-color: $gray-300;
}

// Module LinkBoxen

.linkbox {
    position: relative;
    &::after {
        content: "";
        height: 95%;
        width: 100%;
        background-color: $gray-300;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        @include media-breakpoint-up(lg) {
            height: 300px;
        }
    }

    &__link {
        position: relative;
        z-index: 1;
        overflow: hidden;
        img {
            transition: all 400ms ease;
        }

        &:hover {
            img {
                transform: scale(1.03);
            }
        }
    }
}

// Module Map

.map {
    margin: 1rem 0 3rem;
    > div {
        padding: 5rem 0 !important;
    }
}

.grid-colum-wrap {
    .image {
        p {
            margin: 0;
        }
    }
}

// Module Column - Layouts

.gray-block-full {
    position: relative;
    z-index: 1;
    .grid-colum-wrap {
        &::before {
            display: none;
            @include media-breakpoint-up(lg) {
                content: "";
                display: block;
                background-color: $gray-300;
                height: 636px;
                width: 9999px;
                position: absolute;
                z-index: -1;
                right: 0;
                top: -209px;
                left: 0;
                transform: translateX(-25%);
            }
        }
    }
}
.gray-block {
    position: relative;
    z-index: 1;
    .grid-colum-wrap {
        &::before {
            display: none;
            @include media-breakpoint-up(lg) {
                content: "";
                display: block;
                background-color: $gray-300;
                height: 636px;
                width: 50.76%;
                position: absolute;
                z-index: -1;
                right: 0;
                top: -320px;
            }
        }
    }
}

h2.gray-block {
    .grid-colum-wrap {
        &::before {
            display: none;
            @include media-breakpoint-up(lg) {
                content: "";
                display: block;
                background-color: $gray-300;
                height: 520px;
                width: 100%;
                position: absolute;
                z-index: -1;
                right: -46%;
                top: auto;
                bottom: -395px;
            }
        }
    }
}

.versetzt {
    .grid-colum-wrap {
        &::before {
            display: none;
            @include media-breakpoint-up(lg) {
                content: "";
                display: block;
                background-color: $gray-300;
                height: 300px;
                width: 800px;
                position: absolute;
                z-index: 0;
                width: 44%;
                z-index: 0;
                left: 0;
                transform: translateY(70px);
            }
        }
    }
    img.image-fluid {
        width: 100%;
        height: 540px;
        object-fit: cover;
        object-position: top;

        @include media-breakpoint-up(lg) {
            width: 100%;
            height: 440px;
        }
    }

    .colum-1,
    .colum-2,
    .colum-3 {
        z-index: 2;
    }

    .colum-1 {
        @include media-breakpoint-up(lg) {
            position: relative;
            padding-top: 6rem;
        }
    }

    .colum-3 {
        @include media-breakpoint-up(lg) {
            padding-left: 4rem;
            padding-right: 4rem;
            padding-top: 15rem;
        }
    }
}

// Module Swiper
.swiper {
    width: 100%;

    .swiper-button-next,
    .swiper-button-prev {
        color: $primary;
    }

    .swiper-scrollbar-drag {
        background-color: $primary;
        cursor: all-scroll;
    }

    .swiper-slide {
        text-align: left;
        font-size: 18px;
        // background: #fff;
        display: flex;
        flex-direction: column;
        padding: 2rem 0;
    }
}

.leistung-scroll,
.timeline {
    // margin-top: -2rem;
    &.swiper-horizontal {
        > .swiper-scrollbar {
            width: 90%;
            right: 1%;
            margin: auto;
        }
    }

    .swiper-scrollbar {
        bottom: 21px;
    }

    .swiper-slide {
        padding: 2rem 1rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 20px;
        height: 20px;
        color: $primary;
        top: 96%;

        &::after {
            font-size: 1rem;
        }
    }
}

.leistung-scroll {
    h3 {
        text-transform: uppercase;
    }
}

.timeline {
    .swiper-pagination-progressbar {
        bottom: 24px;
        top: auto;
        width: 90%;
        right: 0;
        margin: auto;

        .swiper-pagination-progressbar-fill {
            background-color: $primary;
        }
    }
}

.leistung__align-opt2 {
    position: relative;
    z-index: 2;
}

.leistung {
    position: relative;
    z-index: 2;

    &__text {
        color: $primary;
    }

    &__title {
        color: $black;
        font-weight: bold;
        margin-bottom: 2rem;
    }
    &__image {
        img {
            object-fit: cover;
            object-position: center;
            height: 200px;
            width: 100%;
            @include media-breakpoint-up(lg) {
                height: 400px;
            }
        }
    }

    &__align {
        &-opt1 {
            .leistung__text {
                order: 1;
                @include media-breakpoint-up(lg) {
                    order: 1;
                }
            }
            .leistung__image {
                order: 2;
                @include media-breakpoint-up(lg) {
                    order: 2;
                }
            }
        }
        &-opt2 {
            .leistung__text {
                order: 1;
                @include media-breakpoint-up(lg) {
                    order: 2;
                }
            }
            .leistung__image {
                order: 2;
                @include media-breakpoint-up(lg) {
                    order: 1;
                }
            }
        }
    }
}

.leistung-scroll {
    margin-top: 3rem;

    .swiper-button-next,
    .swiper-button-prev {
        top: 100%;
    }

    .swiper-scrollbar {
        bottom: 9px;
    }
}

// Module Gallery
.gallery {
    position: relative;
    z-index: 2;
    .lightbox-item {
        overflow: hidden;
        position: relative;
        height: 260px;
        z-index: 1;

        &:hover {
            .lightbox-img {
                transform: scale(1.03);
            }
        }

        &.height-2 {
            @include media-breakpoint-up(lg) {
                height: 300px;
                margin-top: -1rem;
            }
        }

        &.height-3 {
            @include media-breakpoint-up(lg) {
                height: 300px;
                margin-top: 1.5rem;
            }
        }

        &.height-2,
        &.height-3 {
        }
    }

    .lightbox-img {
        display: block;
        transition: all 300ms ease-in-out;
        object-fit: cover;
        object-position: center;
        width: calc(100% - 24px);
        height: 100%;
        position: absolute;
    }
}

// Module Two Colum
.bg-red-image-box {
    .grid-colum-wrap .row {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .colum-1 {
        order: 2;
        @include media-breakpoint-up(lg) {
            padding-right: 0;
            order: 1;
        }

        .colum-inner {
            background-color: $primary;
            color: $white;
            padding: 3rem;

            h2,
            h3 {
                color: $white;
            }
        }
    }

    .colum-2 {
        // display: none;
        padding: 0;
        order: 1;
        @include media-breakpoint-up(lg) {
            display: block;
            order: 2;
        }
    }
}

.jobs {
    .row:nth-child(even) {
        background-color: $gray-100;
    }
}

.referenz {
    .lightbox-img {
        cursor: pointer;
    }

    &__image {
        width: 100%;
        height: 440px;
        object-fit: cover;
    }
}
