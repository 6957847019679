// Compile "main.scss" as "css/main.css"

/**
 * Import Bootstrap sources
 */

//@import "../../../node_modules/bootstrap/scss/bootstrap";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here: https://getbootstrap.com/docs/5.0/customize/sass
// Options
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: false;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

$spacer: 1rem;

// Grayscale vars
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Color vars
$black: #333333;
$white: #fff;
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// main color vars
$primary: #8c1024;
$secondary: $gray-600;

// Fonts
$font-family-sans-serif: "Roboto", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif;

$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$link-decoration: none;

$font-size-base: 1.125rem;
$headings-font-family: "Domine", serif;
$headings-color: $primary;

// Nav
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 0;
$nav-link-font-size: 1.2rem;
$nav-link-font-weight: null;
$nav-link-color: $black;
$nav-link-hover-color: $primary;

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed: Uncomment all components that are not needed to reduce the filesize
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../node_modules/bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "../../../node_modules/bootstrap/scss/utilities/api";

@import "header";
@import "swiper";
@import "aos";
@import "hamburger";
@import "footer";
@import "modules";

/**
 * General
 */
/* roboto-300 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/roboto-v30-latin-300.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/roboto-v30-latin-300.eot?#iefix") format("embedded-opentype"),
        url("../fonts/roboto-v30-latin-300.woff2") format("woff2"),
        url("../fonts/roboto-v30-latin-300.woff") format("woff"),
        url("../fonts/roboto-v30-latin-300.ttf") format("truetype"),
        url("../fonts/roboto-v30-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/roboto-v30-latin-regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/roboto-v30-latin-regular.woff2") format("woff2"),
        url("../fonts/roboto-v30-latin-regular.woff") format("woff"),
        url("../fonts/roboto-v30-latin-regular.ttf") format("truetype"),
        url("../fonts/roboto-v30-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/roboto-v30-latin-500.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/roboto-v30-latin-500.eot?#iefix") format("embedded-opentype"),
        url("../fonts/roboto-v30-latin-500.woff2") format("woff2"),
        url("../fonts/roboto-v30-latin-500.woff") format("woff"),
        url("../fonts/roboto-v30-latin-500.ttf") format("truetype"),
        url("../fonts/roboto-v30-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/roboto-v30-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/roboto-v30-latin-700.eot?#iefix") format("embedded-opentype"),
        url("../fonts/roboto-v30-latin-700.woff2") format("woff2"),
        url("../fonts/roboto-v30-latin-700.woff") format("woff"),
        url("../fonts/roboto-v30-latin-700.ttf") format("truetype"),
        url("../fonts/roboto-v30-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/roboto-v30-latin-900.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/roboto-v30-latin-900.eot?#iefix") format("embedded-opentype"),
        url("../fonts/roboto-v30-latin-900.woff2") format("woff2"),
        url("../fonts/roboto-v30-latin-900.woff") format("woff"),
        url("../fonts/roboto-v30-latin-900.ttf") format("truetype"),
        url("../fonts/roboto-v30-latin-900.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* domine-regular - latin */
@font-face {
    font-family: "Domine";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/domine-v19-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/domine-v19-latin-regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/domine-v19-latin-regular.woff2") format("woff2"),
        url("../fonts/domine-v19-latin-regular.woff") format("woff"),
        url("../fonts/domine-v19-latin-regular.ttf") format("truetype"),
        url("../fonts/domine-v19-latin-regular.svg#Domine") format("svg"); /* Legacy iOS */
}

/* domine-500 - latin */
@font-face {
    font-family: "Domine";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/domine-v19-latin-500.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/domine-v19-latin-500.eot?#iefix") format("embedded-opentype"),
        url("../fonts/domine-v19-latin-500.woff2") format("woff2"),
        url("../fonts/domine-v19-latin-500.woff") format("woff"),
        url("../fonts/domine-v19-latin-500.ttf") format("truetype"),
        url("../fonts/domine-v19-latin-500.svg#Domine") format("svg"); /* Legacy iOS */
}

/* domine-600 - latin */
@font-face {
    font-family: "Domine";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/domine-v19-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/domine-v19-latin-600.eot?#iefix") format("embedded-opentype"),
        url("../fonts/domine-v19-latin-600.woff2") format("woff2"),
        url("../fonts/domine-v19-latin-600.woff") format("woff"),
        url("../fonts/domine-v19-latin-600.ttf") format("truetype"),
        url("../fonts/domine-v19-latin-600.svg#Domine") format("svg"); /* Legacy iOS */
}

/* domine-700 - latin */
@font-face {
    font-family: "Domine";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/domine-v19-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/domine-v19-latin-700.eot?#iefix") format("embedded-opentype"),
        url("../fonts/domine-v19-latin-700.woff2") format("woff2"),
        url("../fonts/domine-v19-latin-700.woff") format("woff"),
        url("../fonts/domine-v19-latin-700.ttf") format("truetype"),
        url("../fonts/domine-v19-latin-700.svg#Domine") format("svg"); /* Legacy iOS */
}

html {
    scroll-padding: 80px;
}

img {
    vertical-align: middle;
}

span,
path,
div,
img {
    outline: none !important;
}

.icons,
.edit-icon {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headings-font-family;
}

body {
    overflow-x: hidden;
    margin-top: 100px !important;

    @include media-breakpoint-up(lg) {
        margin-top: 0 !important;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 1.2rem;
    }

    &.site {
        .wrapper {
            transition: all 300ms linear;

            @include media-breakpoint-up(lg) {
                margin-top: 120px !important;
            }
        }
    }
}

// Spinner
.spinner-wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 999999;
}

.main {
    padding-top: 1px;
}

.bg-gray {
    background-color: $gray-300;
}

.blockquote {
    max-width: 992px;
    margin: auto;
}

.blockquote-footer {
    color: $primary;
    margin-top: 10px;
}

.scroller {
    $width: 54px;
    $height: 54px;

    position: fixed;
    top: 20%;
    left: 0;
    z-index: 20;
    display: none;

    @include media-breakpoint-up(xl) {
        display: block;
    }

    &__phone-icon,
    &__totop a {
        position: relative;
        background-color: $primary;
        width: calc($width / 2);
        height: calc($height / 2);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 15;
        transition: all 300ms ease-in;

        @include media-breakpoint-up(lg) {
            width: $width;
            height: $height;
        }

        svg {
            color: $white;
            width: 15px;
            height: 15px;

            @include media-breakpoint-up(lg) {
                width: 25px;
                height: 25px;
            }
        }

        &:hover {
            background-color: lighten($primary, 5);
        }
    }

    &__contact {
        display: flex;
        position: relative;
    }

    &__contact-form {
        position: absolute;
        left: $width;
        padding: 2rem;
        width: 725px;
        color: $white;
        background-color: $primary;
        transform: translateX(-900px);
        transition: all 500ms ease-in;

        &.open {
            transform: translateX(0);
        }

        .form-label {
            display: none;
        }

        @media screen and (max-height: 730px) {
            top: -124px;
        }
    }

    &__contact-title {
        position: relative;
        width: 27px;
        height: 160px;
        color: $white;
        background-color: $primary;
        cursor: pointer;
        transition: all 300ms ease-in;
        z-index: 20;

        @include media-breakpoint-up(lg) {
            width: $width;
            height: 250px;
        }

        span {
            display: block;
            transform: rotate(-90deg) translateX(12%);
            width: 130px;
            position: absolute;
            font-size: 1rem;
            letter-spacing: 0;
            left: -51px;
            top: 45%;
            z-index: 20;

            @include media-breakpoint-up(lg) {
                top: 50%;
                left: -73px;
                font-size: 1.4rem;
                letter-spacing: 2px;
                width: 200px;
            }
        }

        &:hover {
            background-color: lighten($primary, 5);
        }
    }

    &__phone {
        position: relative;
        cursor: pointer;

        &-number {
            position: absolute;
            background-color: $primary;
            color: $white;
            height: $height;
            min-width: 125px;
            top: 0;
            left: $width;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            transform: translateX(-200px);
            transition: all 300ms ease-in;

            @include media-breakpoint-up(xxl) {
                min-width: 175px;
            }

            &.open {
                transform: translateX(0);
            }
        }
    }
}
