.footer {
    position: relative;
    margin-top: 5rem;

    &::before {
        content: "";
        width: 30px;
        height: 30px;
        background-color: $white;
        background-image: url("../img/logo.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        position: absolute;
        top: -15px;
        left: 0;
        right: 0;
        z-index: 1;
        margin: auto;
    }
}
